import { Link } from "gatsby"
import * as React from "react"
import * as styles from './indiceLuoghi.module.scss'
import { Lang, luoghi } from "./pagine"

export function IndiceLuoghi(props: { lang: Lang }) {

	return <ul className={styles.luogo}>
		{
			Object.values(luoghi).map(p => {
				const l = p[props.lang]
				return <li key={p.id}><Link title={l.nome} to={l.url}>{l.nome}</Link></li>
			})
		}
	</ul>
}