import * as React from "react"
import * as styles from './chiSono.module.scss'
import { ImagoPicture } from "./ImagoPic"



export function ChiSiamo(props: {
	children?: React.ReactNode
}) {
	return <div className={styles.chiSiamo}>{props.children}</div>
}


export function ChiSono(props: {
	nome: string
	imgSrc: string
	children?: React.ReactNode
}) {


	return <div className={styles.chiSono}>
		<ImagoPicture name={props.imgSrc} />
		<div className={styles.nome}>{props.nome}</div>
		{props.children}
	</div>
}