import * as React from "react"
import { Helmet } from "react-helmet";


export function StdHelmet(props: {
	title: string
	description?: string
	canonical: string
}) {
	return <Helmet>
		<meta charSet="utf-8" />
		<title>{props.title}</title>
		<link rel="canonical" href={props.canonical} />
		{props.description && <meta name="description" content={props.description} />}


		<link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
		<link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
		<link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
		<link rel="manifest" href="/site.webmanifest" />
		<link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
		<meta name="msapplication-TileColor" content="#da532c" />
		<meta name="theme-color" content="#ffffff" />


		<meta property="og:title" content={props.title} />
		<meta property="og:description" content={props.description} />
		<meta property="og:image" content="https://www.antoniogallenga.it/social.png" />
		<meta property="og:url" content={props.canonical} />
		<meta property="og:type" content="website" />
		<meta name="twitter:card" content="summary_large_image" />

		<meta property="og:site_name" content="Antonio Gallenga" />
		<meta name="twitter:image:alt" content="Antonio Gallenga" />

	</Helmet>
}