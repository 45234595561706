import * as React from "react"



export function FbPage() {
	return <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fantonio.gallenga&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" width="340" height="500" style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameBorder="0" allowFullScreen={true} allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>

	// 	<div
	// 		className="fb-page"
	// 		data-href="https://www.facebook.com/antonio.gallenga"
	// 		data-tabs="timeline"
	// 		data-width=""
	// 		data-height=""
	// 		data-small-header="false"
	// 		data-adapt-container-width="true"
	// 		data-hide-cover="false"
	// 		data-show-facepile="true"
	// 	>
	// 		<blockquote cite="https://www.facebook.com/antonio.gallenga" className="fb-xfbml-parse-ignore">
	// 			<a href="https://www.facebook.com/antonio.gallenga">Da Antonio Gallenga</a>
	// 		</blockquote>
	// 	</div>
	// </>
}