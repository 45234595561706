import * as React from "react"
import { Content } from "./content"
import { Footer } from "./footer"
import Header2 from "./header2"
import * as styles from './paginaTestoWrapper.module.scss'
import { Lang, pagine } from "./pagine"
import { StdHelmet } from "./StdHelmet"

export function PaginaTesto(props: {
	lang: Lang,
	name?: string,
	title?: string
	children?: React.ReactNode
}) {
	const title = props.name ? pagine[props.name][props.lang].nome : props.title
	return <>
		<StdHelmet
			title={"Antonio Gallenga | " + title}
			canonical={`https://www.antoniogallenga.it/${props.lang}/p/${props.name}`}
		/>

		<div className={styles.sfondo}>
			<div className={styles.contenuto}>
				<Header2 lang={props.lang} />
				<Content>{props.children}</Content>
				<Footer lang={props.lang} />
			</div>
		</div>
	</>
}


