import * as React from "react"
import * as styles from './componentiPagine.module.scss'
import { ImagoPicture } from "./ImagoPic"

export { IndiceLuoghi } from "./indiceLuoghi"
export { PaginaTesto } from "./paginaTesto"
export { ChiSono, ChiSiamo } from "./chiSono"
export { FbPage } from './FbPage'
import { Lang as OLang } from './pagine'

export type Lang = OLang

type ImgProps = {
	nome: string
	alt?: string
	didascalia?: string
	href?: string
	w?: string
}



function targetPerUrl(url: string | undefined) {
	if (!url)
		return undefined
	if (url.startsWith('https://www.antoniogallenga.it'))
		return undefined
	if (url.startsWith('http'))
		return '_blank'
	return undefined
}

function Img(props: ImgProps) {

	// if (props.nome == 'ritratto.PNG') {
	// 	return <img width="1299" height="1490" {...imagoSrc(props.nome)} alt={props.alt} />
	// }

	if (props.href)
		return <a href={props.href} target={targetPerUrl(props.href)} ><ImagoPicture name={props.nome} alt={props.alt} /></a>
	else
		return <ImagoPicture name={props.nome} alt={props.alt} />
}

function BaseImg(props: ImgProps & { altraClasse: string }) {
	return <div className={styles.image + ' ' + props.altraClasse} style={{ width: props.w ? (props.w + 'em') : undefined }}>
		<Img {...props} />
		{props.didascalia && <div>{props.didascalia}</div>}
	</div>
}

export function ImgDestra(props: ImgProps) {
	return <BaseImg {...props} altraClasse={styles.imageDestra} />
}

export function ImgSinistra(props: ImgProps) {
	return <BaseImg {...props} altraClasse={styles.imageSinistra} />
}

export function ImgCentro(props: ImgProps) {
	return <BaseImg {...props} altraClasse={styles.imageCentro} />
}

export function ImgLogo(props: ImgProps) {
	return <BaseImg {...props} altraClasse={styles.imageLogo} />
}

export function Citazione(props: { children?: React.ReactNode }) {
	return <aside className={styles.citazione}>
		<cite>
			{props.children}
		</cite>
	</aside>
}


export function A(props: {
	children?: React.ReactNode
	href?: string
	target?: string
}) {
	return <a
		href={props.href}
		target={targetPerUrl(props.href)}
	>{props.children}</a>
}